import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "room-reservations"
    }}>{`Room Reservations`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`On the room booking interface page, library customers will by default see a listing of all public-facing rooms offered by the library. Each room’s hours of availability and booking time increments will determine the format of the availability bar.`}</p>
    <p>{`Dark green indicates the room is available at the given time.
Light green reflects the currently selected time and duration of the reservation.
Red means the room is unavailable – either due to closure or existing room use.`}</p>
    <p>{`Users may filter by location/room or maximum capacity (using the highest capacity layout), which will remove rooms from the list. Filtering by date will retain all applicable rooms and will show each room’s schedule and availability for that date.`}</p>
    <p>{`To find more information about each room, click the letter “i" icon near each room’s name. `}</p>
    <p>{`To make a request for booking, click “Book Now”.`}</p>
    <h2 {...{
      "id": "create-a-booking"
    }}>{`Create a Booking`}</h2>
    <p>{`After selecting a room via “Book Now”, the user will be presented with a series of fields to configure their request. If the user is not logged in with a library account, they will be prompted to log in using their library credentials. Depending on the library policies, they may also be permitted to “Book as Guest” which does not require a library card but will require the user to enter their name and email address.`}</p>
    <p>{`Note that some fields may have an “information” icon near the label – hover over that icon to view a description of the associated field.`}</p>
    <ol>
      <li parentName="ol">{`First, select the desired booking date and confirm the timeframe for room usage is available (dark green).`}</li>
      <li parentName="ol">{`Select the start time either by typing the time into the “Booking Time” field, or by clicking the dark green bar segment associated with the start time.`}</li>
      <li parentName="ol">{`Select the end time by typing the time into the “Booking End Time” field.`}</li>
      <li parentName="ol">{`For many rooms, “Room Layout” and “Booking Type” are selectable and the user may proceed with the defaults or choose the options that apply to their booking.`}</li>
      <li parentName="ol">{`If there is a cost associated with the room, the fee may adjust based on the amount of time requested, the layout requested, and the booking type, depending on library policies.`}</li>
      <li parentName="ol">{`Custom questions may follow these fields and should be answered by the user. Required custom questions are denoted with an asterisk.`}</li>
      <li parentName="ol">{`Click “Add Booking”. A confirmation prompt will be displayed and if the user is satisfied with their request, click “OK” to confirm the booking.`}</li>
      <li parentName="ol">{`A successful confirmation popup will appear either:
a.	Confirming the booking if the room is not mediated and the reservation is automatically approved.
b.	Confirming receipt of the booking request if the room is mediated. Library staff will review the request and either approve or deny it as appropriate.`}</li>
    </ol>
    <p>{`Your first room will need to be created from scratch; subsequent rooms can mirror the settings of any previously created rooms. It’s useful then to create rooms in a logical order to maximize the overlap and save time.`}</p>
    <h2 {...{
      "id": "room-management"
    }}>{`Room Management`}</h2>
    <p>{`Once the room is created, you’ll be able to edit the following settings.`}</p>
    <ol>
      <li parentName="ol">{`Room Name (required)`}</li>
      <li parentName="ol">{`Room Location (required)`}</li>
      <li parentName="ol">{`Room Description (some text is required)`}</li>
      <li parentName="ol">{`Room Person Email (this is meant to identify the staff member responsible for the room so other staff members know who to contact with questions related to it)`}</li>
      <li parentName="ol">{`Room Parent (used for split/multipurpose rooms with dividers, or rooms with other dependencies – e.g. Room A and Room B can be booked individually OR combined into a single meeting room AB)`}</li>
      <li parentName="ol">{`Staff Visibility (can the room be booked by staff for internal meetings or library events)`}</li>
      <li parentName="ol">{`Public Visibility (can the public view and request/book this room)`}</li>
      <li parentName="ol">{`Guest Bookings (if enabled, the public can request/book this room without a library account. A name and email address is required.)`}</li>
      <li parentName="ol">{`Terms & Conditions Acceptance and Insurance Acceptance (these are configured as a part of your platform setup and will only be available if those policies are provided during the implementation process.)`}</li>
      <li parentName="ol">{`Require Approval (if yes, library staff must approve a booking request. If no, then a valid request will automatically be approved by the system.)`}</li>
      <li parentName="ol">{`Buffer Time Between Bookings (represents the minimum number of minutes between each room use when requested by the public. Staff may override this limit.)`}</li>
      <li parentName="ol">{`Time Increments (length of each unit of booking time for the room)`}</li>
      <li parentName="ol">{`Hide Cost from Patrons (when selected, the cost for the room will not be displayed and library staff would contact the booker to relay the cost of the room and manage payment)`}</li>
      <li parentName="ol">{`Room Images (use one or more images to promote your room. The top image will be the default image. Images must be 1000 pixels wide by 500 pixels high at a minimum, and will be automatically resized to fit.)`}</li>
      <li parentName="ol">{`Room Note (information viewable by staff only about the room and its use)`}</li>
      <li parentName="ol">{`Custom Questions (ask users requesting the room specific questions of the following types.
a.	Short Answer (numerical responses)
b.	Long Answer (paragraph responses)
c.	Multiple Choice (choose one option)
d.	Checkboxes (choose multiple options)
e.	Dropdown (select one from a dropdown menu)`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">Don’t forget to save changes after editing any of these settings!</Alert>
    <p>{`On the righthand side, more room parameters are editable.`}</p>
    <ol>
      <li parentName="ol">{`Availability
a.	Edit the hours and days the room is available each week.
b.	Note that you may have multiple windows of availability within a single day.`}</li>
      <li parentName="ol">{`Layouts – each layout has the following parameters:
a.	Name
b.	Description
c.	Seats – maximum capacity
d.	Default – is this layout the default in the list of layouts?
e.	Max Booking Lead Time – how far in advance can the public request the room?
f.	Min Booking Lead Time – how much notice does the library require?
g.	Max Duration – for how long can this room be booked in a single booking?
h.	Min Duration – the smallest amount of time required for a booking
i.	Additional Setup Time – will block off X minutes prior to the booking
j.	Additional Cleanup Time – will block off X minutes after the booking
k.	Additional Cost – will add X amount to the cost of the booking as a flat rate
l.	Visibility – if no, only the staff will be able to view and select this layout for the room`}</li>
      <li parentName="ol">{`Booking Types
a.	Name
b.	Description
c.	Hourly Rate – will calculate the cost of the room given the amount of time requested
d.	Default – is this layout the default in the list of booking types?
e.	Max Booking Lead Time – how far in advance can the public request the room?
f.	Min Booking Lead Time – how much notice does the library require?
g.	Max Duration – for how long can this room be booked in a single booking?
h.	Min Duration – the smallest amount of time required for a booking
i.	Public Visibility – if no, only the staff will be able to view and select this layout for the room`}</li>
      <li parentName="ol">{`Links
a.	Room QR code and URL that will display this individual room
b.	Location QR code and URL that will display rooms at the location containing this room`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">Don’t forget to save changes after editing any of these settings!</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      